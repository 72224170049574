export const products = [
  {
    name: "Info & Option",
    fileName: "INFO PAGE2023",
    isUpdate: true,
  },
  {
    name: "235 Aspen",
    fileName: "235_Aspen",
    isUpdate: true,
  },
  {
    name: "247 Harmony",
    fileName: "247_Harmony",
    isUpdate: true
  },
  {
    name: "310 Zola",
    fileName: "310_Zola",
    isNew: true,
  },
  {
    name: "322 Marne",
    fileName: "322_Marne",
    isUpdate: true,
  },
  {
    name: "335 Aspire",
    fileName: "335_Aspire",
    isNew: true
  },
  {
    name: "349 Enzo",
    fileName: "349_Enzo",
    isUpdate: true,
  },
  {
    name: "352 Shannon",
    fileName: "352_Shannon",
    isUpdate: true,
  },
  {
    name: "360 Ethan",
    fileName: "360_Ethan",
    isUpdate: true,
  },
  {
    name: "363 Jewel",
    fileName: "363_Jewel",
    isUpdate: true,
  },
  {
    name: "364 Dallas",
    fileName: "364_Dallas",
    isNew: true,
  },
  {
    name: "370 Lotus",
    fileName: "370_Lotus",
    isNew: true,
  },
  {
    name: "408 Ferndale",
    fileName: "408_Ferndale",
    isUpdate: true
  },
  {
    name: "417 Sandrine",
    fileName: "417_Sandrine",
    isNew: true,
  },
  {
    name: "426 Antonia",
    fileName: "426_Antonia",
    isNew: true,
  },
  {
    name: "455 Drew",
    fileName: "455_Drew",
    isUpdate: true,
  },
  {
    name: "463 Everest",
    fileName: "463_Everest",
    isUpdate: true,
  },
  {
    name: "467 Jett",
    fileName: "467_Jett",
    isUpdate: true,
  },
  {
    name: "485 Stuart",
    fileName: "485_Stuart",
    isNew: true,
  },
  {
    name: "488 Trenton",
    fileName: "488_Trenton",
    isNew: true,
  },
  {
    name: "503 Mira",
    fileName: "503_Mira",
    isUpdate: true,
  },
  {
    name: "505 Rialto",
    fileName: "505_Rialto",
    isUpdate: true
  },
  {
    name: "510 Summit",
    fileName: "510_Summit",
    isUpdate: true,
  },
  {
    name: "511 Quinn",
    fileName: "511_Quinn",
    isUpdate: true,
  },
  {
    name: "512 Pinnacle",
    fileName: "512_Pinnacle",
    isUpdate: true,
  },
  {
    name: "515 Lancer",
    fileName: "515_Lancer",
    isUpdate: true,
  },
  {
    name: "516 Bennett",
    fileName: "516_Bennett",
    isUpdate: true,
  },
  {
    name: "519 Astor",
    fileName: "519_Astor",
    isUpdate: true,
  },
  {
    name: "520 Norman",
    fileName: "520_Norman",
    isUpdate: true
  },
  {
    name: "521 James",
    fileName: "521_James",
    isUpdate: true,
  },
  {
    name: "525 Pearl",
    fileName: "525_Pearl",
    isUpdate: true,
  },
  {
    name: "530 Greyson",
    fileName: "530_Greyson",
    isUpdate: true
  },
  {
    name: "532 Harbor Town",
    fileName: "532_Harbor_Town",
    isUpdate: true,
  },
  {
    name: "533 Charleston",
    fileName: "533_Charleston",
    isUpdate: true,
  },
  {
    name: "538 Adam",
    fileName: "538_Adam",
    isUpdate: true
  },
  {
    name: "539 Luna",
    fileName: "539_Luna",
    isNew: true
  },
  {
    name: "541 Avenger",
    fileName: "541_Avenger",
    isNew: true
  },
  {
    name: "549 Hanover",
    fileName: "549_Hanover",
    isUpdate: true,
  },
  {
    name: "550 Cardinal",
    fileName: "550_Cardinal",
    isUpdate: true
  },
  {
    name: "551 Napier",
    fileName: "551 Napier",
    isUpdate: true,
  },
  {
    name: "552 Stanley",
    fileName: "552_Stanley",
    isUpdate: true,
  },
  {
    name: "554 Dreamtime",
    fileName: "554_Dreamtime",
    isUpdate: true,
  },
  {
    name: "555 Ella",
    fileName: "555_Ella",
    isNew: true
  },
  {
    name: "559 Eden",
    fileName: "559_Eden",
    isUpdate: true,
  },
  {
    name: "560 Grand Canyon",
    fileName: "560_Grand_Canyon",
    isUpdate: true,
  },
  {
    name: "561 Branson",
    fileName: "561_Branson",
    isUpdate: true,
  },
  {
    name: "563 Gibson",
    fileName: "563_Gibson",
    isUpdate: true,
  },
  {
    name: "577 Clarkston",
    fileName: "577_Clarkston",
    isUpdate: true
  },
  {
    name: "582 Maverick",
    fileName: "582_Maverick",
    isUpdate: true,
  },
  {
    name: "584 Parker",
    fileName: "584_Parker",
    isUpdate: true,
  },
  {
    name: "591 Cortland",
    fileName: "591_Cortland",
    isUpdate: true,
  },
  // Page 2
  {
    name: "609 Jules",
    fileName: "609_Jules",
    isUpdate: true,
  },
  {
    name: "612 Sterling",
    fileName: "612_Sterling",
    isUpdate: true,
  },
  {
    name: "651 Conroy",
    fileName: "651_Conroy",
    isNew: true
  },
  {
    name: "663 Paxton",
    fileName: "663_Paxton",
    isNew: true
  },
  {
    name: "672 Jones",
    fileName: "672 Jones",
    isNew: true
  },
  {
    name: "704 Nora",
    fileName: "704_Nora",
    isUpdate: true,
  },
  {
    name: "705 Rayna",
    fileName: "705_Rayna",
    isUpdate: true,
  },
  {
    name: "706 Jay",
    fileName: "706_Jay",
    isUpdate: true
  },
  {
    name: "708 Empire",
    fileName: "708_Empire",
    isUpdate: true
  },
  {
    name: "713 Tripoli",
    fileName: "713_Tripoli",
    isUpdate: true
  },
  {
    name: "715 Wayland",
    fileName: "715_Wayland",
    isUpdate: true
  },
  {
    name: "719 Stratus",
    fileName: "719_Stratus",
    isUpdate: true,
  },
  {
    name: "724 Trouper",
    fileName: "724 Trouper",
  },
  {
    name: "726 Fortune",
    fileName: "726_Fortune",
    isUpdate: true,
  },
  {
    name: "730 Dunkirk",
    fileName: "730_Dunkirk",
    isUpdate: true,
  },
  {
    name: "732 Kirk",
    fileName: "732_Kirk",
    isUpdate: true,
  },
  {
    name: "735 Andover",
    fileName: "735_Andover",
    isUpdate: true,
  },
  {
    name: "741 Serenity",
    fileName: "741_Serenity",
    isUpdate: true,
  },
  {
    name: "744 Forum",
    fileName: "744_Forum",
    isUpdate: true,
  },
  {
    name: "747 Lander",
    fileName: "747_Lander",
    isNew: true
  },
  {
    name: "752 Barrett",
    fileName: "752_Barrett",
    isUpdate: true
  },
  {
    name: "754 Talladega",
    fileName: "754_Talladega",
    isUpdate: true,
  },
  {
    name: "760 Douglas",
    fileName: "760_Douglas",
    isUpdate: true,
  },
  {
    name: "765 Rowan",
    fileName: "765_Rowan",
    isUpdate: true,
  },
  {
    name: "777 Randall",
    fileName: "777_Randall",
    isNew: true
  },
  {
    name: "784 Spectator",
    fileName: "784_Spectator",
    isUpdate: true
  },
  {
    name: "785 Ruby",
    fileName: "785_Ruby",
    isUpdate: true
  },
  {
    name: "803 Grand Mira",
    fileName: "803_Grand Mira",
    isUpdate: true
  },
  {
    name: "810 Grand Summit",
    fileName: "810_Grand Summit",
    isUpdate: true,
  },
  {
    name: "812 Grand Pinnacle",
    fileName: "812_Grand Pinnacle",
    isUpdate: true,
  },
  {
    name: "813 Grand Tripoli",
    fileName: "813_Grand Tripoli",
    isUpdate: true,
  },
  // ];

  // export const col3 = [
  // Page 2
  {
    name: "828 Grand Rapids",
    fileName: "828_Grand Rapids",
    isUpdate: true,
  },
  {
    name: "835 Grand Andover",
    fileName: "835_Grand Andover",
    isUpdate: true,
  },
  {
    name: "852 Grand Stanley",
    fileName: "852_Grand Stanley",
    isUpdate: true,
  },
  {
    name: "859 Grand Eden",
    fileName: "859_Grand Eden",
    isUpdate: true,
  },
  {
    name: "863 Grand Everest",
    fileName: "863_Grand Everest",
    isNew: true
  },
  {
    name: "891 Grand Cortland",
    fileName: "891_Grand Cortland",
    isUpdate: true,
  },
  {
    name: "903 Mira XL",
    fileName: "903_Mira XL",
    isUpdate: true
  },
  {
    name: "910 Summit XL",
    fileName: "910_Summit XL",
    isNew: true
  },
  {
    name: "912 Pinnacle XL",
    fileName: "912_Pinnacle XL",
    isNew: true,
  },
  {
    name: "913 Tripoli",
    fileName: "913_Tripoli XL",
    isUpdate: true,
  },
  {
    name: "928 Rapids XL",
    fileName: "928_Rapids XL",
    isNew: true,
  },
  {
    name: "932 Harbor Town XL",
    fileName: "932_Harbor Town XL",
    isUpdate: true,
  },
  {
    name: "933 Grand Chaleston",
    fileName: "933 Grand Chaleston",
  },
  {
    name: "935 Andover XL",
    fileName: "935_Andover XL",
    isUpdate: true,
  },
  {
    name: "952 Stanley XL",
    fileName: "952_Stanley XL",
    isUpdate: true,
  },
  {
    name: "959 Eden XL",
    fileName: "959_Eden XL",
    isUpdate: true,
  },
  {
    name: "960 Ethan XL",
    fileName: "960_Ethan XL",
    isNew: true
  },
  {
    name: "991 Cortland XL",
    fileName: "991_Cortland XL",
    isUpdate: true,
  },
  {
    name: "NTT Bauer",
    fileName: "NTT_Bauer",
    isNew: true
  },
  {
    name: "NTT Larrson",
    fileName: "NTT_Larrson",
    isNew: true
  },
  {
    name: "NTT Nillson",
    fileName: "NTT_Nillson",
    isNew: true
  },
  {
    name: "NTT Prince",
    fileName: "NTT_Prince",
    isNew: true
  },
  {
    name: "NTT Roslin",
    fileName: "NTT_Roslin",
    isNew: true
  },
];
