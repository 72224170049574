<template>
  <div class="catalog-desktop">
    <div class="content">
      <div class="page-title mb-5 is-uppercase text-center">
        <strong>Product Catalog</strong>
      </div>
      <b-loading
        :is-full-page="true"
        v-model="isLoading"
        :can-cancel="true"
      ></b-loading>
      <div class="columns">
        <div
          v-for="(column, index) in productInPage[page - 1]"
          :key="keyForColumn[index]"
          :class="{ 'center-column': index % 2 !== 0 }"
          class="column"
        >
          <ul v-for="c in column" :key="c.name">
            <li>
              <a
                class="has-text-black"
                rel="noreferrer"
                :href="`/categories/${c.fileName}.pdf`"
                target="_blank"
              >
                <b-tooltip label="Click to download">
                  <i class="fas fa-caret-right"></i> {{ c.name }}
                  <sup class="is-new" v-if="c.isNew">New</sup>
                  <sup class="is-update" v-if="c.isUpdate">Update</sup>
                </b-tooltip>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- <section class="is-hidden-mobile"> -->
    <v-pagination
      @input="onPaginate"
      color="#fff"
      v-model="current"
      :length="totalPage"
      circle
    ></v-pagination>
    <!-- </section> -->
  </div>
</template>
<script>
import axios from "axios";
import { products } from "@/categories.js";
export default {
  data() {
    return {
      current: 1,
      page: 1,
      isLoading: false,
      itemsPerColumn: 19,
      columnsPerPage: 3,
      productInPage: [[]],
      keyForColumn: ["1", "2", "3", "4", "5", "6"],
      totalPage: 0,
    };
  },
  beforeMount() {
    const totalitem = products.length;
    const itemsPerPage = Math.round(this.itemsPerColumn * this.columnsPerPage);
    const totalPage = Math.ceil(totalitem / itemsPerPage);
    this.totalPage = totalPage;
    let slicedCount = 0;
    Array.from({ length: totalPage }, (_, page) => {
      this.productInPage[page] = Array.from(
        { length: this.columnsPerPage },
        () => {
          const result = products.slice(
            slicedCount,
            slicedCount + this.itemsPerColumn
          );
          slicedCount += this.itemsPerColumn;
          return result;
        }
      );
    });
  },
  methods: {
    onPaginate(pageNumber) {
      this.page = pageNumber;
    },
    pdf(fileName) {
      // this.isLoading = true;
      axios({
        url: `/categories/${fileName}.pdf`,
        method: "GET",
        responseType: "blob", // important
      }).then((response) => {
        this.isLoading = false;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${fileName}.pdf`);
        document.body.appendChild(link);
        link.click();
        this.$buefy.toast.open({
          message: "Catalog file is starting to download!",
          type: "is-success",
        });
      });
    },
  },
};
</script>
<style lang="scss">
.catalog-desktop {
  background-color: white;
  .page-title {
    color: #000;
    background-color: #cccccc;
    letter-spacing: 5px;
    strong {
      color: #000;
    }
    padding: 20px 0;
    font-size: 34px;

    @media screen and (max-width: 1023px) {
      font-size: 24px;
    }
  }

  .center-column {
    @media screen and (min-width: 1024px) {
      border-right: solid 1px;
      border-left: solid 1px;
      border-color: #c9c9c9;
    }
  }

  .column {
    padding: 0rem 2em;
  }

  .content {
    color: black;

    ul {
      list-style: none;
      li {
        cursor: pointer;
      }
    }
  }

  .v-pagination__navigation {
    border: none;
    border-radius: 0;
    box-shadow: none;
  }

  .theme--light.v-pagination .v-pagination__item {
    color: #000;
  }
  .v-pagination__item--active {
    background-color: black !important;
    color: white !important;
  }
  .is-new {
    color: red;
    font-size: 8px;
  }
  .is-update {
    color: blue;
    font-size: 8px;
  }
}
</style>
