<template>
  <div class="container">
    <DesktopView />
  </div>
</template>
<script>
import DesktopView from "@/components/catalogs/DesktopView.vue";
export default {
  components: { DesktopView },
};
</script>
